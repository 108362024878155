<template>
  <div class="home">
    <div class="home-hero">
      <container>
        <div class="home-hero-content">
          <h1 class="h1">{{ globalConfig?.title }}</h1>
          <p class="lead">{{ globalConfig?.subTitle }}</p>
        </div>
        <div class="home-hero-background">
          <img src="/home-creced.jpg" alt="creced" title="creced" />
        </div>
      </container>
    </div>
    <container class="pt-4 pb-4 pt-lg-6 pb-lg-8">
      <h2 class="h3 mb-3">{{ node.title }}</h2>
      <div
        v-if="node.body"
        class="home-content content"
        v-html="node.body"
      ></div>
    </container>
  </div>
</template>

<script lang="ts" setup>
import { definePageMeta } from '#imports'
import type { NodePageFragment } from '#graphql-operations'
defineOptions({
  name: 'Homepage',
})
definePageMeta({
  name: 'home',
})

setBreadcrumbLinks()
const globalConfig = await useGlobalConfig()

provide('isFrontPage', true)

const nuxtRoute = useRoute()

// Get the data.
const { data: query } = await useAsyncData(nuxtRoute.path, async () => {
  return useGraphqlQuery('route', {
    path: nuxtRoute.path,
  }).then((v) => v.data)
})

// // Handles redirects and metatags.
const { entity: node } = await useDrupalRoute<NodePageFragment>(query.value)
await renderPageDependencies()
</script>
